<template>
    <div class="pay" id="agentTitle">
        <Imgbox v-if="!checking"  class="topheader" src="https://img.nnabc.cn/202303241426.jpg" />
        <Card style="margin-top:-5px" class="paybox img">
			<div class="testtopcentent">
				<div class="center tophedae">一次购买立即查看所有内容</div>
				<div  v-if="!checking" >
					<p style="text-align: center;color: #525252;">{{xm}}测名结果</p>
					<img style="width: 100%;" src="http://cdn1.zhiyileiju.cn/WX20210907-102219%402x.jpeg" >
				</div>
			</div>
			<div class="pricebox">
				<div class="disflex">
					<div class="realprice" >限时优惠：￥{{ price.measure_amount }}元</div>
					<span>距优惠结束</span>
				</div>
				<div class="disflex" style="margin-top:8px">
					<span class="discountprice">原价￥{{ price.fake_amount }}</span>
					<van-count-down millisecond color="#fd0101" :time="time" format="HH:mm:ss:SS" />
				</div>
                <div class="red_packet_discounts_hint" v-if="is_fixed_red_packets" style="padding-bottom:.28rem;margin-top: 0.1rem;">
                    <span >优惠红包</span> 
                    <span>-￥50.00元</span>
                </div>
                <div v-if="this.orderinfo.fromk == 'tk2'" style="font-size:8px;color:#ddd;line-height:1.5em">【划线价：可能是商品的专柜价、吊牌加、零售价、指导价或该商品曾经展示过的销售价等，并非《价格法》、《禁止价格欺诈行为的规定》规定的“原价”，仅供参考】</div>
			</div>
            <!-- <van-button type="primary" size="large" color="#04C967" style="margin-bottom: 14px;font-size:22px" icon="http://cdn1.zhiyileiju.cn/wechaticon111.png" round block @click="getpayurl('wechat','v1_testnamepay_wechat')">微信支付</van-button>
            <van-button type="primary" size="large" color="#1B95D4" style="font-size:22px" icon="http://cdn1.zhiyileiju.cn/alipayicon111.png" round block @click="getpayurl('alipay','v1_testnamepay_alipay')">支付宝</van-button> -->
            <van-button type="primary" size="large" color="#04C967" style="margin-bottom: 14px;font-size:22px" icon="http://cdn1.zhiyileiju.cn/wechaticon111.png" round block @click="getpayurl('wechat','v1_testnamepay_wechat')">微信支付</van-button>
            <van-button type="primary" size="large" color="#1B95D4" style="font-size:22px" icon="http://cdn1.zhiyileiju.cn/alipayicon111.png" round block v-if="!is_weixn" @click="getpayurl('alipay','v1_testnamepay_alipay')">支付宝</van-button>
            <p class="intip center">
                已有<span style="color: #fd0101;font-weight:bold">9,316,618</span>人查看，98.7%认为对人生发展有帮助
            </p>
            <img class="center" style="width: 100%" src="https://img.nnabc.cn/baozhang.png" alt="" />
        </Card>
        <h2  v-if="!checking" class="center tiph2">支付后你将看到以下所有信息</h2>
		
		<Card  v-if="!checking"  class="img userCard">
			<div class="disflex" style="justify-content: flex-start;">
				<div class="center namebox11" >
					<div v-for="(item,index) in orderinfo.hanzimean.xm" class="WeinameLl_Con1" :key="index">{{item}}</div>
				</div>
				<div calss="birthdaybox disflex" style="font-size: 12px;">
					<div >性别：{{ orderinfo.sex }}</div>
					<div class="bir" >农历/阴历：{{ orderinfo.nongli.gzYear }}年{{
                        orderinfo.nongli.monthCn + orderinfo.nongli.dayCn
                        }}</div>
				</div>
			</div>
			<div class="cardtit" >
				三才五格打分
			</div>
			<dl>
				<dd class="lh2e wgbox">
					<ul class="wg-list">
						<li>
							<div class="wg-unit">
								外格<span class="wg-num">?</span>
							</div>
						</li>
						<li scwg-name="1">
							<div v-for="(value, index) in xm" :key="value" class="wg-unit">
								<strong>{{ value }}</strong><span class="wg-num">{{orderinfo.hanzimean.kxbihua.split("#")[index]? orderinfo.hanzimean.kxbihua.split("#")[index]:'？'}}</span>
							</div>
						</li>
						<li>
							<div class="wg-unit">
								天格<span class="wg-num">?</span>
							</div>
							<div class="wg-unit">
								人格<span class="wg-num">?</span>
							</div>
							<div class="wg-unit">
								地格<span class="wg-num">?</span>
							</div>
						</li>
					</ul>
				</dd>
			</dl>
			<div class="center">
				总格<span class="wg-num" style="display: inline-block;">?</span>
			</div>
		</Card>
		
		
        <Card  v-if="!checking" class="img userCard">
           <div class="cardtit" >
           八字命盘打分(示例)
           </div>
            <table class="table" border="0" cellspacing="0">
                <tbody>
                    <tr>
                        <th style="width: 24%">示例</th>
                        <th style="width: 19%">年柱</th>
                        <th style="width: 19%">月柱</th>
                        <th style="width: 19%">日柱</th>
                        <th style="width: 19%">时柱</th>
                    </tr>
                    <tr >
                        <td>出生时间</td>
                        <td k="year_zhu">{{ orderinfo.eightChara.bazi[0].y }}</td>
                        <td k="month_zhu">{{ orderinfo.eightChara.bazi[1].m }}</td>
                        <td k="day_zhu">{{ orderinfo.eightChara.bazi[2].d }}</td>
                        <td k="hour_zhu">{{ orderinfo.eightChara.bazi[3].h }}</td>
                    </tr>
                    <tr>
                        <td>纳音</td>
                        <td k="year_ly">{{ orderinfo.eightChara.nayin[0].y }}</td>
                        <td k="month_ly">{{ orderinfo.eightChara.nayin[1].m }}</td>
                        <td k="day_ly">{{ orderinfo.eightChara.nayin[2].d }}</td>
                        <td k="hour_ly">{{ orderinfo.eightChara.nayin[3].h }}</td>
                    </tr>
                    <tr>
                        <td>喜用分析</td>
                        <td colspan="4">
                            <div class="pifen disflex" wx="木">
                                <span>木</span>
                                <div class="progress">
                                    <span class="back_jin" :style="'width:' + orderinfo.askTao.qk_score_ratio['木']"></span>
                                </div>
                                <span class="fs">{{
                                    orderinfo.askTao.qk_score_ratio["木"]
                                    }}</span>
                            </div>
                            <div class="pifen disflex" wx="火">
                                <span>火</span>
                                <div class="progress">
                                    <span class="back_mu" :style="'width:' + orderinfo.askTao.qk_score_ratio['火']"></span>
                                </div>
                                <span class="fs">{{ orderinfo.askTao.qk_score_ratio["火"] }}
                                </span>
                            </div>
                            <div class="pifen disflex" wx="土">
                                <span>土</span>
                                <div class="progress">
                                    <span class="back_shui" :style="'width:' + orderinfo.askTao.qk_score_ratio['土']"></span>
                                </div>
                                <span class="fs">{{
                                    orderinfo.askTao.qk_score_ratio["土"]
                                    }}</span>
                            </div>
                            <div class="pifen disflex" wx="金">
                                <span>金</span>
                                <div class="progress">
                                    <span class="back_huo" :style="'width:' + orderinfo.askTao.qk_score_ratio['金']"></span>
                                </div>
                                <span class="fs">{{
                                    orderinfo.askTao.qk_score_ratio["金"]
                                    }}</span>
                            </div>
                            <div class="pifen disflex" wx="水">
                                <span>水</span>
                                <div class="progress">
                                    <span class="back_tu" :style="'width:' + orderinfo.askTao.qk_score_ratio['金']"></span>
                                </div>
                                <span class="fs">{{
                                    orderinfo.askTao.qk_score_ratio["水"]
                                    }}</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </Card>
        <Card  v-if="!checking" class="img userCard">
            <div class="title2 disflex">您的姓名分数</div>
            <div class="infoImg">
                <div class="info">
                    <div class="infoText shadow">
                        <p>1、分析名字与您生辰八字的相合度</p>
                        <p>2、分析名字的三才五格</p>
                        <p>3、得到名字的综合评分</p>
                    </div>
                </div>
            </div>
			<van-button class="getbutton" size="large" color="#B95353" type="info" @click="pay('v1_testnamepay_content_btn')">
			   <!-- <template #icon>
			        <img style="width:15px;margin-right:8px" src="http://cdn1.zhiyileiju.cn/lock%20close.png" alt="" />
			    </template> -->
			   支付后查看全部分析</van-button>
        </Card>
        <Card  v-if="!checking" class="img userCard">
            <div class="title2 disflex">您的名字运势</div>
            <div class="infoImg">
                <div class="info">
                    <div class="infoText shadow">
                        <p>1、判断您名字对事业财运影响</p>
                        <p>2、判断您名字对恋爱婚姻影响</p>
                        <p>3、判断您名字对未来运势影响</p>
                    </div>
                </div>
            </div>
			<van-button class="getbutton" size="large" color="#B95353" type="info" @click="pay('v1_testnamepay_content_btn')">
			    <!-- <template #icon>
			        <img style="width:15px;margin-right:8px" src="http://cdn1.zhiyileiju.cn/lock%20close.png" alt="" />
			    </template> -->
			    支付后查看全部分析</van-button>
        </Card>
        <Card  v-if="!checking" class="img userCard">
            <div class="title2 disflex">您的五行喜用</div>
            <div class="infoImg">
                <div class="info">
                    <div class="infoText shadow">
                        <p>1、分析您生辰八字五行含量</p>
                        <p>2、分析您最弱和最强的五行</p>
                        <p>3、告诉您增旺哪类五行对运势最好</p>
                    </div>
                </div>
            </div>
			<van-button class="getbutton" size="large" color="#B95353" type="info" native-type="submit" @click="pay('v1_testnamepay_content_btn')">
			   <!-- <template #icon>
			        <img style="width:15px;margin-right:8px" src="http://cdn1.zhiyileiju.cn/lock%20close.png" alt="" />
			    </template> -->
			    支付后查看全部分析</van-button>
        </Card>
        <Card  v-if="!checking" class="img userCard">
            <div class="title2 disflex">未来发展建议</div>
            <div class="infoImg">
                <div class="info">
                    <div class="infoText shadow">
                        <p>1、分析您生辰八字的命格特征</p>
                        <p>2、告诉您该命格下未来发展运势</p>
                        <p>3、告诉您一些未来发展建议</p>
                    </div>
                </div>
            </div>
			<van-button class="getbutton" size="large" color="#B95353" type="info" native-type="submit" @click="pay('v1_testnamepay_content_btn')">
			   <!-- <template #icon>
			        <img style="width:15px;margin-right:8px" src="http://cdn1.zhiyileiju.cn/lock%20close.png" alt="" />
			    </template> -->
			    支付后查看全部分析</van-button>
        </Card>
        <Card class="img">
            <Comments  v-if="!checking" type="testname" />
            <div   v-if="!checking" class="title2 disflex">最新支付订单</div>
            <orderscroll  v-if="!checking" type="testname" />
            <div class="title2 disflex mt-12">常见问题</div>
            <van-collapse v-model="activeNames" :accordion="true">
                <van-collapse-item :is-link="false" title="1、你们是专业正规的起名公司吗？如何相信？" name="1">因为是在网上，我可以理解你的心情，但是我们公司的信誉，您绝对放心。首先我们是正规成立的公司，是具有工商认证经营的。其次我们网站是开通了快手推广、百度推广、搜狗推广、360推广，都是经过这些平台的全面审核才上线的，您可以放心体验。
                </van-collapse-item>
                <van-collapse-item :is-link="false" title="2、付款以后如何获取姓名测试结果？ " name="2">付款以后会自动跳转到姓名测试结果页面，查看测试结果非常方便快捷。</van-collapse-item>
                <van-collapse-item :is-link="false" title="3、八字的五行，是缺什么就补什么吗？" name="3">不是。需要区别对待，精确分析，真正做到“需要什么，则补什么”。否则就是简单加减，补救容易失误。
                </van-collapse-item>
                <van-collapse-item :is-link="false" title="4、测试了名字不满意怎么办" name="4">诸姓名测试的不满意率只有千分之二，原因大多是因为操作不熟练、需求不明确、追求满分体验造成的。 如果您有疑问请及时联系客服，我们一定能完美解决您所提出的问题，争取满意百分百。
                </van-collapse-item>
            </van-collapse>
			<div class="center">
				<img width="100%" src="http://cdn1.zhiyileiju.cn/WechatIMG144.jpeg" >
			</div>
		<!-- 	<div class="center" style="font-size: 24px;font-weight: bold;color:#C94B4B;margin-top:30px;">专业 · 高标准 · 值得信赖</div>
			<div class="center" style="padding-bottom: 60px;">
				<img style="width: 220px;margin-top: 15px;" src="../assets/WechatIMG169.png" >
			</div> -->
        </Card>

        <!-- <van-button type="primary" class="fixbtn"  :class="fixedbtn == true ? 'isFixed' : ''"  color="#B95353" block @click="pay('v1_testnamepay_bottom_btn')">支付后查看测名结果</van-button> -->
		
        <div class="bottom_footer_chunk" v-if="fixedbtn">
                <div class="bottom_fixed_chunk">
                    <div class="fixed_img_"><img src="https://img.nnabc.cn/202303241113.png" alt=""></div>
                    <div class="top_chunk">你的姓名测试报告已生成！</div>
                    <div class="content_chunk">
                        <div class="left_chunk">
                            <div class="top">多维度详细分析名字的好坏</div>
                            <div class="img_chunk"><img src="https://img.nnabc.cn/20230324145112.png" alt=""></div>
                        </div>
                        <div class="center_line"></div>
                        <div class="right_chunk">
                            <div class="text_chunk1">
                                <span class="left">{{ price.measure_amount }}</span>
                                <span class="right">距优惠结束</span>
                            </div>
                            <div class="text_chunk2">
                                <span class="left">原价{{price.fake_amount}}元</span>
                                <span class="count_down"><van-count-down  millisecond :time="600000" format="HH:mm:ss:SS" style="color:#333333;line-height: .18rem !important;font-size: .18rem !important;"/></span>
                            </div>
                            <div class="text_chunk3">
                                <van-radio-group v-model="radio"> 
                                    <div class=" radio_chunk" @click="radioClick('1')"><span class="WeChat_chunk">微信支付</span><van-radio name="1" checked-color="#C94B4B"></van-radio></div>
                                    <div class=" radio_chunk" v-if="!is_weixn" @click="radioClick('2')"><span class="Alipay_chunk">支付宝支付</span><van-radio name="2" checked-color="#C94B4B"></van-radio></div>
                                </van-radio-group>
                            </div>
                        </div>
                    </div>
                    <!-- 
                        @click="getpayurl('wechat','v1_testnamepay_wechat')"
                        v-if="!is_weixn" @click="getpayurl('alipay','v1_testnamepay_alipay')">
                     -->
                    <div class="button_chunk animation_scaling" @click="getpayurl(radio === '1' ? 'wechat' : 'alipay','1')"><img src="https://img.nnabc.cn/20230324145113.png" alt=""></div>
                </div>
        </div>



        <van-overlay :show="shopPayConfirm" z-index="10" lock-scroll>
            <div class="wrapper center" @click.stop>
                <!-- <div class="block">
                    <div class="title">请确认支付是否完成</div>
                    <div class="payed">
                        <van-button round block @click="Dcheckorder" class="Dcheckorder" type="primary">付款成功，查看测名结果</van-button>
                    </div>
                    <div class="unpay" @click="unpay">
                        未支付，我再考虑一下
                    </div>
                </div> -->
                <div class="block">
                    <div class="title">
                        <p class="top_title_">离姓名解析只差1步</p>
                        <p class="bottom_title_" style="margin-top:-9px;">支付后查看姓名详批</p>
                    </div>
                    <div class="bg_box_chunk_">
                            <img :src="buttom_top_src_img" alt="">
                    </div>
                    <div class="payed" style="margin-top: 11%;" @click="getpayurl('','')">
                        <img src="http://cdn1.zhiyileiju.cn/WechatIMG600.jpeg" alt="">
                    </div>
                    <div class="payed" @click="unpay">
                        <img v-if="buttom_src_img_fal" :src="buttom_src_img"  alt="">

                    </div>
                    <div class="payed"  @click="Dcheckorder">
                        <img src="http://cdn1.zhiyileiju.cn/WechatIMG605.png" alt="">
                    </div>
                    <div class="countbox center">
                        <van-count-down :time="counttime" millisecond>
                            <template #default="timeData">
                                <span class="block_">{{ timeData.hours }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ timeData.minutes }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ timeData.seconds }}</span>
                                <span class="colon">:</span>
                                <span class="block_">{{ parseInt(timeData.milliseconds / 10) }}</span>
                            </template>	
                        </van-count-down>
                        <span style="color:#525252;font-size:12px;margin-left:10px;font-weight: 500;">后失效</span>
                    </div>
                </div>
            </div>
        </van-overlay>
        <div class="center" style="margin: 10px 0;" v-if="this.orderinfo.fromk !== 'tk2'">客服联系微信：sunwei13844768688</div>
			
		<div v-if="ifFqudao"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有   武汉道森梯媒文化传播有限公司</div>
			<div>地址：武汉市武昌区徐东大街128号</div>
			<div>电话：18975441215</div>
		</div>
        <LookReportResultFixed/>

    </div>
</template>
<script>
import Comments from "../components/Comment.vue";
import vueSeamlessScroll from "vue-seamless-scroll";
import orderscroll from "../components/OrderScroll.vue";
import axios from "../utils/request";
import solarLunar from "../components/datepick/solarlunar";
import LookReportResultFixed from "../components/LookReportResultFixed.vue";
import { CountDown } from "vant";

import { Overlay } from "vant";
import { Toast } from "vant";

import { Dialog } from "vant";

import router from "../router";

import report from "../utils/stat";



export default {
    name: "Pay",
    components: {
        Comments,
        vueSeamlessScroll,
        vanCountDown: CountDown,
        vanOverlay: Overlay,
        vanToast: Toast,
        orderscroll,
        LookReportResultFixed
    },
    data() {
        return {
            radio:'1',
            is_fixed_red_packets:sessionStorage.getItem('is_fixed_red_packet') === 'true' ? true : false,
            buttom_top_src_img:'http://cdn1.zhiyileiju.cn/WechatIMG610.jpeg',
            buttom_src_img:'http://cdn1.zhiyileiju.cn/WechatIMG606.png',
            buttom_src_img_fal:true,
            counttime:10*60*1000,
            listData: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            orderlist: [],
            activeNames: ["1"],
            showPayDialog: false,
            showfixPayBtn: true,
            shopPayConfirm: false,
             // shopPayConfirm: true,//支付后弹窗
            fixedbtn: false,
            orderinfo: {
                bDate: [2021],
                nongli: {},
                eightChara: {
                    bazi: [{ y: "辛丑" }, { m: "乙未" }, { d: "甲子" }, { h: "甲子" }],
                    nayin: [
                        { y: "壁上土" },
                        { m: "沙中金" },
                        { d: "海中金" },
                        { h: "海中金" },
                    ],
                },
                askTao: {
                    qk_score_ratio: {
                        金: "6%",
                        木: "11%",
                        水: "25%",
                        火: "12%",
                        土: "46%",
                    },
                },
				scwg:{
					"fiveGirl":
					{"天":16,"地":10,"人":24,"总":24,"外":2},
				},
				hanzimean:{xm: "李晓丽", ft: "李曉丽", pinyin: "lǐ#xiǎo#lì#", kxbihua: "7#16#19#", wx: "火火火"}
            },
            time: 1000 * 60 * 30,
            price: {
                base_level: null,
                created_at: null,
                fake_amount: "198.00",
                fast_amount: null,
                id: 1,
                true_amount: "",
                type: 1,
                updated_at: null,
            },
            orderfakedata: [{
                    name: "王*慧",
                    time: "12分钟前",
                },
                {
                    name: "李*",
                    time: "7分钟前",
                },
                {
                    name: "孙*飞",
                    time: "3分钟前",
                },
                {
                    name: "刘*云",
                    time: "5分钟前",
                },
                {
                    name: "赵*",
                    time: "1分钟前",
                },
                {
                    name: "周**",
                    time: "3分钟前",
                },
                {
                    name: "李*惠",
                    time: "3分钟前",
                },
                {
                    name: "古*歌",
					time: "5分钟前",
                },
            ],
        };
    },
    destroyed() {
        this.timers && clearTimeout(this.timers);
    },
	beforeDestroy(){
		 this.timers && clearTimeout(this.timers);
	},
    async created() {
        
        if ( window.localStorage.getItem('cm_red_packet_type') === null) {
            this.buttom_src_img = 'http://cdn1.zhiyileiju.cn/WechatIMG606.png'
            this.buttom_top_src_img = 'http://cdn1.zhiyileiju.cn/WechatIMG610.jpeg'
        }else{
            this.buttom_src_img_fal = false
            this.buttom_top_src_img = 'http://cdn1.zhiyileiju.cn/WechatIMG612.jpeg'
        }
		report("v1_testnamepay");
        console.log(this.$route)
        let paytype = this.$route.params.type;
        this.orderid = this.$route.params.id;
        this.type = this.$route.params.type;
        if (paytype != 10) {
            try {
                await this.mcheckorder();
            } catch (err) {}
            this.shopPayConfirm = true;
        } else {}
        this.getorderinfo(this.$route.params.id);
        this.getFakeOrder();
        await this.getprice();
        this.showToast();
		window.addEventListener("scroll", this.handleScroll);
    },

    computed: {
        is_weixn() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                return true;
            } else {
                return false;
            }
        },
        defaultOption() {
            return {
                step: 0.5, // 数值越大速度滚动越快
                limitMoveNum: this.listData.length,
                hoverStop: true, // 是否开启鼠标悬停stop
                direction: 1, // 0向下 1向上 2向左 3向右
                openWatch: false, // 开启数据实时监控刷新dom
                singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
                openTouch: false,
            };
        },
		xm(){
			return this.orderinfo.xname+this.orderinfo.name
		},
        dialogwidth() {
            return window.innerWidth * 0.9;
        },
       checking(){
			let from = this.$route.query.from;
			let allowfrom = ['ft3','tk1','tk2','tk3','tk4','d1','d2','a10'];
			if (allowfrom.indexOf(from) > -1) {
				return true
			} else {
				return false
			}
       },
	   ifFqudao(){
			let from = this.$route.query.from;
				let allowfrom = ['ft1', 'ft2',];
			if (allowfrom.indexOf(from) > -1) {
				return true
			} else {
				return false
			}
	   },
	   ifvo(){
			let from = this.$route.query.from;
			let allowfrom = ['tk1','tk2','tk3','tk4'];
			if (allowfrom.indexOf(from) > -1) {
				return true
			} else {
				return false
			}
	   },
       
    },
	async mounted() {
        sessionStorage.setItem('orderid',this.$route.params.id);

	    if (this.is_weixn) {
				let wxconfig = await this.getWxConfig();
				wx.config({
					debug: false,
					appId: wxconfig.appId,
					timestamp: wxconfig.timestamp,
					nonceStr: wxconfig.nonceStr,
					signature: wxconfig.signature,
					jsApiList: ['chooseWXPay']
				});
			}
	
	    document
	        .getElementById("agentTitle")
	        .scrollIntoView({ block: "start", behavior: "smooth" });
			document.title = "诸葛国学测名";
	},
    methods: {
        pay(action) {
			this.hasclick = true;
			document
			    .getElementById("agentTitle")
			    .scrollIntoView({ block: "start", behavior: "smooth" });
            report(action);
        },
        unpay(){
            // router.back();
            if ( window.localStorage.getItem('cm_red_packet_type') === null) {
                this.$router.replace('/aitestname')
            }else{
                // location.href = "weixin://dl/business/?t=dqnSN5t1Ehe";
            }
        },
		handleScroll() {
			var scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop;
			if (scrollTop > 300) {
				this.fixedbtn = true;
			} else {
				this.fixedbtn = false;
			}
		},
        async getFakeOrder() {
            let info = await axios.post("/ekaf", {
                type: 2,
                perPage: 15,
                page: 1,
                fromk: this.$route.query.from
            });
            this.orderlist = info.data.data;
        },

        async getWxConfig() {
				let url = window.location.href;

				let data = await axios.post("/payment/wechatconf", {
					url
				})
				return data.data.data.data;
			},

        showToast() {
            let t = this;
            let arr = this.orderfakedata;
            // let data = arr[Math.floor(Math.random() * arr.length)];
            // Toast({
            //     message: `${data.time} ${data.name}支付了${this.price.measure_amount}元测试姓名`,
            //     position: "bottom",
            //     duration: 2000,
            // });
            this.timers = setTimeout(function() {
                t.showToast();
            }, 5000);
        },
        radioClick(id){
            // console.log('radioClick',id)
            this.radio = id
        },

        async timeout(time) {
            return new Promise((sol, ret) => {
                setTimeout(() => {
                    sol()
                }, time);
            })
        },

        async getpayurl(payway, aciton) {
            if ( payway === '') {
                payway = this.$route.params.type === '1' ? 'alipay' : 'wechat'
             }
            Toast.loading({
                message: '请稍后...',
                forbidClick: true,
            });
			if(this.hasclick){
				report(aciton+"_content");
			}else{
				report(aciton);
			}

            if ( this.is_weixn ) {
                setInterval( ()=> {this.checkorder(orderid, 2);}, 2000)
                
            }
    
            let orderid = this.orderid;
            let type = payway == "alipay" ? 1 : 2;
            this.type = type;
            let orderinfo = await axios.get("/order/" + orderid);
            if (
                orderinfo.data.data.order_status == "已完成" ||
                orderinfo.data.data.order_status == "已支付"
            ) {
                
                Dialog.confirm({
                    message: "当前订单已支付",
                    showCancelButton: false,
                }).then(() => {
                    sessionStorage.setItem('aitestsuccess','1');
                    let arr_from_ = ['pdgzhcm','pdwzcm','hygzhcm','hywzcm','qmwzcm','cm2']
                    if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                        router.replace({ name: "aitestsuccess", params: { id: orderid, type } }).catch(() => {});
                    }else{
                        router.replace({ name: "testresult", params: { id: orderid, type } }).catch(() => {});
                    }
                });
                return;
            }
            let t;
            setTimeout(async function() {
                await t.checkorder(orderid, type);
            }, 2000);
            try {
                let confirm_order_data_ = {
                    order_id: orderid,
                    paytype: type,
                }
                await this.confirmOrder(confirm_order_data_);
                Toast.clear()
                // location.href = `https://api.zhiyileiju.cn/api/v1/payment/${payway}/${orderid}`;
                this.shopPayConfirm = true;

                if (this.is_weixn) {
                    let payinfo = await axios.get(
                        //location.href = `https://api.zhiyileiju.cn/api/v1/payment/${paymentType === '1'?res.data.data.alipay : res.data.data.wechat}/${order_ids}/${urls_}`; //原来
                        `https://api.zhiyileiju.cn/api/v1/payment/${payway}/${orderid}/wechat`)
                    let pay = payinfo.data.data;

                    wx.ready(function() {
                        wx.chooseWXPay({
                            timestamp: pay.timeStamp,
                            nonceStr: pay.nonceStr,
                            package: pay.package,
                            signType: pay.signType,
                            paySign: pay.paySign, // 支付签名
                            success: function(res) {
                                let arr_from_ = ['pdgzhcm','pdwzcm','hygzhcm','hywzcm','qmwzcm','cm2']
                                if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                                    router.replace({
                                        name: "aitestsuccess",
                                        params: { id:orderid, type:2 }
                                    }).catch(() => {});
                                }else{
                                    router.replace({
                                        name: "testresult",
                                        params: { id:orderid, type:2 }
                                    }).catch(() => {});
                                }
                            }
                        });
                    });


                } else {
                    location.href = `https://api.zhiyileiju.cn/api/v1/payment/${payway}/${orderid}`;
                }



            } catch (err) {}
        },
        async getprice() {
            let from = this.$route.query.from || 10;
            let price = await axios.get(`/getSalesPrice?fromk=${from}`);
          
            this.price = price.data.data[0];
        },
        async getorderinfo(id) {
            let data = await axios.get("/order/" + id);
            this.orderinfo = data.data.data;
            // console.log('fromk',this.orderinfo.fromk === 'tk2')
            // if (
            //     this.orderinfo.order_status == "已支付" ||
            //     this.orderinfo.order_status == "已完成"
            // ) {
            //     Dialog.confirm({
            //         message: "当前订单已支付",
            //         showCancelButton: false,
            //     }).then(() => {
            //         router.replace({ name: "paysuccess", params: { id, type: 1 } });
            //     });
            // } else if (this.orderinfo.order_status == "已关闭") {
            //     Dialog.confirm({
            //         message: "当前订单已关闭",
            //         showCancelButton: false,
            //     }).then(() => {
            //         router.replace({ name: "Index" }).catch(() => {}); // on confirm
            //     });
            // }
            let birth = data.data.data.pub_birth_date ?
                data.data.data.pub_birth_date :
                data.data.data.pub_prebirth_date;
            let fdate = birth.split(" ");
            this.orderinfo.bDate = [...fdate[0].split("-"), ...fdate[1].split(":")];
            this.orderinfo.nongli = solarLunar.solar2lunar(
                this.orderinfo.bDate[0],
                this.orderinfo.bDate[1],
                this.orderinfo.bDate[2]
            );
            let nowtime = Date.now();
            let cDate = data.data.data.created_at.replace(" ", "T");
            let createtime = new Date(cDate).getTime();
            this.time = 10 * 60 * 1000 - nowtime + createtime;
        },
        checkorder(id, type) {
            this.timer = setInterval(async () => {
                try {
                    let data = await axios.post("/payment/query", {
                        paytype: type,
                        order_id: id,
                    });
                    if (data.data.data.is_pay) {
                        sessionStorage.setItem('aitestsuccess','1');
                        let arr_from_ = ['pdgzhcm','pdwzcm','hygzhcm','hywzcm','qmwzcm','cm2']
                        if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                            router.replace({
                                 name: "aitestsuccess",
                                 params: { id, type: data.data.data.type },
                             }).catch(() => {});
                        }else{
                            router.replace({
                                name: "testresult",
                                params: { id, type: data.data.data.type },
                            }).catch(() => {});
                        }
                    }
                } catch (err) {}
            }, 5000);
        },
        async mcheckorder() {
            let data = await axios.post("/payment/query", {
                paytype: this.type,
                order_id: this.orderid,
            });
            if (data.data.data.is_pay) {
                sessionStorage.setItem('aitestsuccess','1');
                let arr_from_ = ['pdgzhcm','pdwzcm','hygzhcm','hywzcm','qmwzcm','cm2']
                if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                    router.replace({
                        name: "aitestsuccess",
                        params: { id: this.orderid, type: data.data.data.paytype },
                    }).catch(() => {});
                }else{
                    router.replace({
                        name: "testresult",
                        params: { id: this.orderid, type: data.data.data.paytype },
                    }).catch(() => {});
                }
            } else {
                Toast.fail("当前订单未支付");
            }
        },
        async Dcheckorder() {
            try {
                this.mcheckorder();
                let data = await axios.post("/payment/query", {
                paytype: this.type,
                order_id: this.orderid,
                });
                if (data.data.data.is_pay) {
                    sessionStorage.setItem('aitestsuccess','1');
                    let arr_from_ = ['pdgzhcm','pdwzcm','hygzhcm','hywzcm','qmwzcm','cm2']
                    if( arr_from_.indexOf(this.$route.query.from) === -1 ) {
                        router.replace({
                            name: "aitestsuccess",
                            params: { id: this.orderid, type: data.data.data.paytype },
                        }).catch(() => {});
                    }else{
                        router.replace({
                            name: "testresult",
                            params: { id: this.orderid, type: data.data.data.paytype },
                        }).catch(() => {});
                    }
                } else {
                    this.getpayurl('','')
                    // Toast.fail("当前订单未支付");
                }

            } catch (err) {
                this.getpayurl('','')
                // Toast.fail("当前订单未支付");
            }
        },
        async confirmOrder(confirm_order_data_) {
            try {
                if ( window.localStorage.getItem('cm_red_packet_type') === null) {
                    let aa = await axios.post("/order/confirm", {
                        order_id: confirm_order_data_.order_id,
                        paytype: confirm_order_data_.paytype,
                    });
                }else{
                    let aa = await axios.post("/order/confirm", {
                        order_id: confirm_order_data_.order_id,
                        paytype: 2,
                        discount_id: 1
                    });
                }
            } catch (err) {}
        },
        bottombtnclick() {
            window.pageYOffset = 0;
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
        },
        handleScroll() {
            var scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (scrollTop > 300) {
                this.fixedbtn = true;
            } else {
                this.fixedbtn = false;
            }
        },
    },
};
</script>
<style scoped>
/* .wrapper {
    width: 100vw;
    height: 100vh;
    max-width: 640px;
    margin: 0 auto;
}

.block {
    width: 80%;
    background-color: white;
    border-radius: 5px;
}

.Dcheckorder {
    width: 85%;
    margin: 10px auto;
    animation: scaleDrew 1.5s ease-in-out infinite;
    font-size: 18px;
}

.block .title {
    line-height: 44px;
    text-align: center;
    font-size: 15px;
    border-bottom: 1px solid #eeeeee;
}

.block .payed {
    color: #b91e1e;
    font-size: 18px;
    line-height: 80px;
    text-align: center;
    border-bottom: 1px solid #eeeeee;
}

.unpay {
    color: #999;
    font-size: 18px;
    text-align: center;
    line-height: 60px;
} */

.wrapper {
    width: 100vw;
    height: 100vh;
    max-width: 640px;
    margin: 0 auto;
}

.block {
    width: 70%;
    height: 70vh;
    background-color: white;
    border-radius: 16px;
}
.block .title {
    line-height: 44px;
    text-align: center;
    font-size: 15px;
    margin-top: 22px;
    margin-bottom: 17px;
}
.block .title p{
    color: #CF031A;
    /* line-height: 44px; */
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    margin: 0;
}
.bg_box_chunk_{
    overflow: hidden;
}
/* .bg_box_chunk_ */
img{ 
    width: 100%;
}

.countbox{
		margin-top:4%;
	}

	.colon {
        display: inline-block;
        margin: 0 4px;
        color: #d12229;
      }
      .block_ {
        display: inline-block;
        width: 20px;
		height: 20px;
        color: #fff;
        font-size: 12px;
        text-align: center;
        background-color: #d12229;
      }
        



.Dcheckorder {
    width: 85%;
    margin: 10px auto;
    animation: scaleDrew 1.5s ease-in-out infinite;
    font-size: 18px;
}



.block .payed {
    width: 82%;
    margin: 0 auto;
    margin-top: 3%;
}

.unpay {
    color: #999;
    font-size: 18px;
    text-align: center;
    line-height: 60px;
}



.dialogbox {
    padding-bottom: 25px;
}

.dialogheader {
    height: 70px;
    background: #d6d5d5;

    font-size: 18pxpx;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #525252;
}

.closeicon {
    width: 30px;
    position: absolute;
    right: 18px;
}

.content {
    margin-top: 15px;
    font-size: 14px;
    font-weight: 400;
    color: #525252;
}
</style>
<style scoped>
.webfooter {
    /* height: 120px; */
    /* background: url("http://cdn1.zhiyileiju.cn/kexin.png") no-repeat top;
  background-size: 250px;
  background-color: #fff; */

    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    line-height: 2em;
    font-size: 14px;
}
dd{
    padding: 0;
    margin: 0;
}

.dialogrealprice {
    text-decoration: line-through;
    font-size: 12px;
    color: #979797;
    margin-right: 5px;
}

.pricewarp {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.tehuiprice {
    color: #fd0101;
    font-size: 14px;
    font-weight: Regular;
    margin-right: 5px;
}

.dialogdisp {
    font-size: 28px;
    font-weight: bold;
    font-family: Microsoft YaHei;
    color: #fd0101;
    line-height: 1em;
}

.red {
    color: #fd0101;
}

.green {
    color: #19aa0f;
}

.paybtnbox {
    width: 80vw;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.isFixed {
    position: fixed;
    font-size: 18px;
    height: 60px;
    bottom: 0;
    z-index: 999;
    max-width: 640px;
    animation: scaleDrew 1.5s ease-in-out infinite;
}
</style>
<style scoped>
.header {
    width: 100vw;
    background: #b95353;
    height: 31px;
    font-size: 18px;
    font-weight: bold;
    color: white;
}

.topheader {
    min-height: 190px;
}

.paybox {
    padding: 14px 20px;
}

.paybox .usertip {
    text-align: left;
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: #020202;
}

.userinforow {
    font-size: 14px;
    font-weight: 400;
    color: #020202;
    margin-top: 10px;
}

.paytip {
    font-size: 12px;
    font-weight: 400;
    color: #b95353;
}

.paytip.h1 {
    font-size: 26px;
    margin-top: 14px;
    margin-bottom: 14px;
    font-weight: bold;
    color: #fd0101;
}

.priceinfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 76vw;
    height: 116px;
    border: 1px solid #aaa79b;
    background: #f2eedf;
}

.paytime {
    display: flex;
    margin-top: 8px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #fd0101;
    align-self: flex-start;
}

.van-count-down {
    color: #fd0101;
}

.pricebox {
    margin-top: 10px;
    margin-bottom: 20px;
}

.realprice {
    font-size: 21px;
    font-weight: 500;
    font-family: Microsoft YaHei;
    color: #fd0101;
    line-height: 1em;
}

.jlyh{
    font-size: 12px;
    font-weight: 500;
    color: #323232;
}

.discountprice {
    font-size: 12px;
    font-weight: 400;
    color: #979797;
    text-decoration: line-through;
}


.pricefooter {
    width: 100%;
    height: 32px;
    background: white;
    font-size: 12px;
    font-weight: 400;
    color: #020202;
}

.img {
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 15px;
    margin-top: 15px;
}

.intip {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #020202;
}

.van-icon img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.tiph2 {
    font-size: 16px;
    font-weight: bold;
    color: #C94B4B;
}
.WeinameLl_Con1{
    width: 40px;
    height: 40px;
    background: url(//cdn.names.org.cn/website/Content/szname/img/NewNameBjk.png) no-repeat;
    background-size: 100%;
    text-align: center;
    line-height: 40px;
    font-size: 24px;
    font-weight: 500;
    margin-right: 7px;
}

.cardtit{
    font-size: 16px;
    font-weight: 500;
    margin:14px 0;
    color: #525252;
}
.birthdaybox{
    height: 40px;
}

.birthdaybox .bir{
    flex-shrink: 2;
    font-size: 12px;
    color: #666;
}
</style>
<style lang="less" scoped>
	@siteColor: #b95353;
	.wgbox {
		padding: 15px;
		text-align: center;
	
		li {
			display: inline-block;
			vertical-align: middle;
			margin-right: 20px;
			border-left: 1px solid @siteColor;
		}
	
		.wg-unit {
			margin: 10px 0;
	
			&::before {
				display: inline-block;
				vertical-align: middle;
				width: 15px;
				height: 1px;
				content: "";
				background-color: @siteColor;
			}
		}
	
		.wg-num {
			display: inline-block;
			vertical-align: middle;
			margin-left: 5px;
			width: 25px;
			height: 25px;
			line-height: 25px;
			color: #b95353;
			background-color: @siteColor;
			color: #fff;
		}
	
		h4 {
			margin-top: 25px;
			font-weight: bold;
			text-align: center;
		}
	}
    .wg-num {
        display: inline-block;
        text-align: center;
        margin-left: 5px;
        width: 25px;
        height: 25px;
        line-height: 25px;
        color: #b95353;
        background-color: @siteColor;
        color: #fff;
	}
</style>
<style scoped>
.infoImg {
    position: relative;
    display: block;
    background: url("http://cdn1.zhiyileiju.cn/z_wap_12.jpeg") 0 0 no-repeat;
    background-size: 100% 100%;
}

.infoText {
    padding: 10px;
    font-size: 14px;
    line-height: 20px;
}

.infoText p {
    margin: 5px 0;
}

.shadow {
    box-shadow: 0 0 8px #ddd;
    background-color: white;
    margin-bottom: 12px;
    border-radius: 6px;
}

.infoImg .info {
    width: 80%;
    margin: 0 auto 10px auto;
    padding: 12% 0;
}

.mt-12 {
    margin-top: 16px;
}

.getbutton {
    border-radius: 6px;
    margin-top: 10px;
	font-size: 20px;
    background: #c94b4b;
}
</style>
<style scoped>
.userCard {
    padding: 14px 19px;
}

.userBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.xingshi {
    width: 60px;
    height: 60px;
    background: url("http://cdn1.zhiyileiju.cn/NewNameBjk.png") 0 0 no-repeat;
    background-size: 100% 100%;
    line-height: 60px;
    text-align: center;
    font-size: 30px;
    margin-right: 10px;
    border: 1px solid #ddd;
    color: #b95353;
}

.userBox p {
    color: #666;
    margin: 7px;
    font-size: 14px;
    font-weight: bold;
    color: #525252;
}

.table {
    width: 100%;
    border: 1px solid #ddd;
    margin: 20px 0;
    font-size: 14px;
}

.table td,
.table th {
    text-align: center;
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid #eddbcd;
    border-right: 1px solid #eddbcd;
}

.table th,
.table td:first-child {
    background: #f2eedf;
}

.table th:first-child {
    background: #fff;
}

.table td:last-child,
.table th:last-child {
    border-right: 0;
}

.pifen {
    width: 90%;
    padding: 0 10px;
    padding-right: 5px;
}

.pifen .mc {
    width: 20px;
    height: 20px;
}

.back_jin {
    background: #fe0;
}

.back_mu {
    background: #0aa90a;
}

.back_shui {
    background: #b5ddec;
}

.back_huo {
    background: #ef3e33;
}

.back_tu {
    background: #92551c;
}

.pifen .progress {
    width: 60%;
    top: 0;
    margin: 0 5px;
}

.pifen .progress span {
    animation: animate-positive 2s;
    transition: width 0.6s ease;
}

.pifen span.fs {
    /* margin: 0 5px; */
    color: #c33936;
    font-size: 14px;
}

.progress,
.progress span {
    width: 100%;
    height: 12px;
    border-radius: 12px;
    display: inline-block;
    text-align: left;
}

.progress {
    background: #f1eae6;
    position: relative;
}

.progress span {
    position: absolute;
}

.disflex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title2 {
    font-size: 18px;
    font-weight: bold;
    color: #b95353;
    justify-content: center;
    margin-bottom: 14px;
}

.testtopcentent{ border: 1px solid rgba(145, 117, 108, 0.38); border-radius: 5px;}

.testtopcentent .tophedae{font-size: 21px; height: 44px;background: #FFF7EC;line-height: 44px;width: 100%; color: red;border-radius: 5px 5px 0 0; border-bottom: 1px solid rgba(145, 117, 108, 0.38); font-weight: 500;
}
	
</style>

<style lang="scss" scoped>
.red_packet_discounts_hint{
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: .24rem;
    white-space: nowrap;
    span:nth-child(1){
        display: flex;
        align-items: center;
        color: #525252;
        &::before{
            content:"";
            margin-right: .13rem;
            display: block;
            width: .22rem;
            height: .25rem;
            background: url("https://img.nnabc.cn/WechatIMG3543.jpeg") no-repeat;
            background-size: 100% 100%;
        }
    }
    span:nth-child(2){
        display: flex;
        align-items: center;
        color: #E15817;
        &::before{
            content:"";
            margin-right: .13rem;
            display: block;
            width: .18rem;
            height: .18rem;
            background: url("https://img.nnabc.cn/WechatIMG3548.pic") no-repeat;
            background-size: 100% 100%;
        }
    }
}
.bottom_footer_chunk{
            width: 7.5rem;
            height: 3.62rem;
            // background: #FFF5E2;
            // box-shadow: 0px -.02rem .21rem .03rem rgba(117,0,16,0.81);
            // border-radius: .30rem .30rem 0px 0px;
            .bottom_fixed_chunk{
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 7.5rem;
                height: 3.62rem;
                background: #fff;
                box-shadow: -.01rem -.03rem .12rem .01rem rgba(33,3,7,0.17);
                border-radius: .30rem .30rem 0px 0px;
                .fixed_img_{
                    position: absolute;
                    width: 1.71rem;
                    height: 1.83rem;
                    left: .38rem;
                    top: -1.06rem;
                }
                .top_chunk{
                    text-indent: 2.31rem;
                    font-size: .36rem;
                    line-height: .36rem;
                    white-space: nowrap;
                    font-weight: bold;
                    color: #C94B4B;
                    margin-top: .18rem;
                }
                .content_chunk{
                    display: flex;
                    width: 7.5rem;
                    justify-content: center;
                    margin-top: .32rem;
                    .left_chunk{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 50%;
                        .top{
                            font-size: .2rem;
                            white-space: nowrap;
                            font-weight: 400;
                            color: #333333;
                        }
                        .img_chunk{
                            width: 3.12rem;
                            height: 1.1rem;
                            margin-top: .24rem;
                        }
                    }
                    .center_line{
                        width: .01rem;
                        height: 1.15rem;
                        background: #000000;
                        opacity: 0.1;
                        margin-top: .4rem;
                    }
                    .right_chunk{
                        width: 50%;
                        .text_chunk1{
                            display: flex;
                            justify-content: space-between;
                            align-items: flex-end;
                            margin-top: -.1rem;
                            .left{
                                display: flex;
                                align-items: center;
                                font-size: .36rem;
                                font-weight: bold;
                                color: #C94B4B;
                                margin-left: .25rem;
                                &::before{
                                    content: "优惠价" ;
                                    display: block;
                                    font-size: .18rem;
                                    line-height: .18rem;
                                    padding: .06rem .1rem;
                                    color: #fff;
                                    background: #C94B4B;
                                    border-radius: .05rem;
                                    margin-right: .14rem;
                                }
                                &::after{
                                    content: "元";
                                    font-size: .18rem;
                                    color: #C94B4B;
                                    margin-top: .1rem;
                                }
                            }
                            .right{
                                font-size: .18rem;
                                font-weight: 400;
                                color: #333333;
                                margin-right: .15rem;
                            }
                        }
                        .text_chunk2{
                            display: flex;
                            justify-content: space-between;
                            align-items:center;
                            .left{
                                font-size: .18rem;
                                line-height: .18rem;
                                font-weight: 400;
                                text-decoration: line-through;
                                color: #333333;
                                margin-left: .25rem;
                            }
                            .count_down{
                                font-size: .18rem;
                                line-height: .18rem;
                                font-weight: 400;
                                color: #333333;
                                margin-right: .15rem;
                            }
                        }
                        .text_chunk3{
                            font-size: .2rem;
                            margin-top: .2rem;
                            .radio_chunk{
                                display: flex;
                                align-items: center;
                                justify-content:space-between;
                                padding-left: .28rem;
                                padding-right: .23rem;
                                box-sizing: content-box;
                                margin-bottom: .05rem;
                                &:last-child{
                                    margin-bottom: 0;
                                }
                                .WeChat_chunk{
                                    display: flex;
                                    align-items: center;
                                    &::before{
                                        content: "";
                                        display: block;
                                        width: .28rem;
                                        height: .26rem;
                                        background: url("https://img.nnabc.cn/202303221909.png") no-repeat;
                                        background-size: 100% 100%;
                                        margin-right: .13rem;
                                    }
                                }
                                .Alipay_chunk{
                                    display: flex;
                                    align-items: center;
                                    &::before{
                                        content: "";
                                        display: block;
                                        width: .26rem;
                                        height: .26rem;
                                        background: url("https://img.nnabc.cn/202303221910.png") no-repeat;
                                        background-size: 100% 100%;
                                        margin-right: .13rem;
                                    }
                                }
                            }
                        }
                    }
                }
                .button_chunk{
                    width: 6.90rem;
                    height: .76rem;
                    margin: auto;
                    margin-top: .22rem;
                }
            }
        }
</style>

<style lang="scss">
    .pay{
        .van-popup{
            background: none;
        }
        .bottom_footer_chunk{
            .van-icon{
                width: .2rem;
                height: .2rem;
                font-size: .5em;
                line-height: .2rem;
                border: 1px solid #C94B4B;
            }
        }
        
    }
</style>